@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "DM", sans-serif;
    font-size: 16px;
    color: #222;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}