.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.footer-links {
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  margin: 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: none;
  }

  a {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0 12px;

    @media all and (max-width: 460px) {
      font-size: 12px;
      margin: 0 8px;
    }

    &:hover {
      opacity: .6;
    }
  }
}

.app-toast-top {
  position: fixed;
  z-index: 65535;
  left: 48px;
  right: 48px;
  bottom: 32px;
  padding: 12px 16px;
  background-color: rgba(200, 200, 200, 0.75);
  border-radius: 32px;
  box-shadow: 0 2px 14px 2px rgb(0 0 0 / 25%);
  color: #222;
  text-align: center;
  backdrop-filter: blur(6px);
}